<template>
  <div class="home-index-wrapper">
    <live-now @liveEmpty="hanldeNowEmpty" />
    <more-live :now-empty="nowEmpty" />
  </div>
</template>
<script>
import LiveNow from './LiveNow.vue';
import MoreLive from './MoreLive.vue';

export default {
  components: {
    LiveNow,
    MoreLive,
  },
  data() {
    return {
      nowEmpty: false,
    };
  },

  methods: {
    hanldeNowEmpty(val) {
      this.nowEmpty = val;
    },
  },
};
</script>
<style lang="scss" scoped>
  .home-index-wrapper {
    width: 1100px;
    margin: 0 auto;
  }
</style>
