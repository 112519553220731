<template>
  <div class="more-live-wrapper">
    <div
      class="title"
      :class="nowEmpty ? 'title-no-live': ''"
    >
      <span>更多直播</span>
      <el-button
        v-if="nowEmpty"
        size="small"
        class="operation-btn"
        plain
        @click="handleMyLive"
      >
        我的Live
      </el-button>
    </div>
    <div
      v-if="moreLiveLists.length"
      class="more-live-lists"
    >
      <div
        v-for="liveItem in moreLiveLists"
        :key="liveItem.id"
        class="live-item"
      >
        <div
          class="live-posters"
          @click="handleJump(liveItem)"
        >
          <img :src="imgCompression(liveItem.posterUrl)">
          <div class="label-wrapper">
            <i :class="getLabelContent(liveItem).icon" />
            {{ getLabelContent(liveItem).text }}
          </div>
        </div>
        <div
          class="label-group"
          @click="handleJump(liveItem)"
        >
          <div
            v-if="liveItem.experienceStatus"
            class="live-experience"
          >
            体验
          </div>
          <overflow-tool-tip
            tool-tip-class="_home-more-live-tooltip_"
            :content="liveItem.liveTheme"
          >
            <div class="live-title">
              {{ liveItem.liveTheme || '-' }}
            </div>
          </overflow-tool-tip>
        </div>
        <div class="live-detail">
          <div class="live-airtime">
            <i class="iconfont icon-v_newsflash" />
            {{ getAirtimeText(liveItem) }}
          </div>
          <div class="appointment-count">
            <i
              v-if="liveItem.totalViewCount < 100000"
              class="appointment-icon iconfont icon-v_hot"
            />
            <img
              v-else
              class="appointment-icon"
              src="@/assets/img/hot.svg"
            >
            {{ getAppointNumber(liveItem) }}
            人{{ getAppointmentText(liveItem) }}
          </div>
        </div>
        <div class="live-operation-btn">
          <el-button
            v-if="liveItem.timeSatisfy "
            type="primary"
            size="small"
            class="watch-btn subscribe-btn"
            :disabled="liveItem.overTime"
            @click="StartLive(liveItem.id)"
          >
            开始直播
          </el-button>
          <el-button
            v-else-if="liveItem.liveRoomTypeEnum === 'SUBSCRIBED'"
            v-sensor:click="{
              $element_id: 'live_appointing',
              consumer_id: profile.uid
            }"
            type="primary"
            size="small"
            class="watch-btn subscribe-btn"
            :disabled="liveItem.subscribed"
            @click="handleOperation(liveItem)"
          >
            {{ liveItem.subscribed ? '已预约' : '立即预约' }}
          </el-button>
          <el-button
            v-else
            size="small"
            class="watch-btn"
            @click="handleOperation(liveItem)"
          >
            查看回放
          </el-button>
        </div>
      </div>
    </div>
    <empty-content
      v-else-if="isEmpty"
      class="empty-class"
    />
    <subscribe-tip-dialog
      :visible="SubscribeTipDialogVisible"
      :content="SubscribeTipDialogContent"
      :dialog-type="dialogType"
      :room-id="roomId"
      :show-footer="showFooter"
      @update:visible="toggleSubscribeTipDialog(false)"
      @updateLists="getMoreLiveLists"
    />
    <!-- 确定是否开始直播 -->
    <start-live-dialog
      :visible="startLiveDialogVisible"
      :live-room-id="roomId"
      @close="startLiveDialogVisible = false"
      @startSucess="handleStartLiveSuccess"
    />
    <qr-dialog
      :visible="bookModel"
      :start-time="subscribRoomPreTime"
      @close="bookModel=false"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { getLiveRoomLists } from '@/services/home';
import { subscribeLive } from '@/services/room';
import { imgCompression } from '@/utils/imgCompression';
import EmptyContent from '@/components/EmptyContent';
import QrDialog from '@/views/components/BookResult/QrDialog';
import OverflowToolTip from '@/components/OverflowToolTip';
import SubscribeTipDialog from './SubscribeTipDialog.vue';
import StartLiveDialog from './StartLiveDialog.vue';

export default {
  components: {
    SubscribeTipDialog,
    EmptyContent,
    QrDialog,
    OverflowToolTip,
    StartLiveDialog,
  },
  props: {
    nowEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEmpty: false,
      moreLiveLists: [],
      SubscribeTipDialogVisible: false,
      roomId: '',
      SubscribeTipDialogContent: '',
      dialogType: '',
      showFooter: false,
      bookModel: false,
      subscribRoomPreTime: '',
      startLiveDialogVisible: false,
      timer: '',
    };
  },
  computed: {
    ...mapState('user', ['isLogin', 'profile']),
  },
  created() {
    this.getMoreLiveLists();
  },
  methods: {
    ...mapActions('login', [
      'showLoginDialog',
    ]),
    imgCompression,
    async getMoreLiveLists() {
      try {
        const res = await getLiveRoomLists({
          page: 1,
          pageSize: 100,
          liveRoomType: 'NOONLINE',
        });
        const { data } = res;
        this.moreLiveLists = [].concat(data);
        this.handleLiveLists();
        this.isEmpty = !this.moreLiveLists.length;
      } catch (error) {
        // console.log(error)
      }
    },
    handleMyLive() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      const routes = this.$router.resolve({
        path: 'myLive',
      });
      window.open(routes.href, '_blank');
    },
    handleLiveLists() {
      let nowTime = this.moreLiveLists[0].currentServerTimeMillis;
      // 如果没有当前用户发布的房间就停止操作；
      const ownerLiveLists = this.moreLiveLists.filter((live) => {
        return (live.liveRoomTypeEnum === 'SUBSCRIBED' && live.roomOwnerUid === this.profile.uid);
      });
      if (!ownerLiveLists.length) return;
      // 对当前用户发布的房间进行倒计时操作
      ownerLiveLists.forEach((item) => {
        if (this.timer) {
          clearInterval(this.timer);
        }
        const { preStartTime } = item;
        this.timer = setInterval(() => {
          nowTime += 1000;
          const _time = preStartTime - nowTime;
          // 小于60分钟或者超过20分钟内，变成开始直播
          if (_time <= 60 * 60 * 1000 && _time >= -20 * 60 * 1000) {
            this.$set(item, 'timeSatisfy', true);
          }
          // 超过20min,开始直播按钮置灰
          if (_time < -20 * 60 * 1000) {
            this.$set(item, 'overTime', true);
            clearInterval(this.timer);
          }
          this.$forceUpdate();
        }, 1000);
      });
    },
    // 开始直播按钮
    async StartLive(id) {
      if (!this.isLogin) {
        const toNext = () => {
          this.getMoreLiveLists();
        };
        this.showLoginDialog({
          callback: toNext,
        });
        return;
      }
      this.roomId = id;
      this.startLiveDialogVisible = true;
    },
    // 开始直播成功，刷新列表
    handleStartLiveSuccess() {
      this.getMoreLiveLists();
    },
    handleOperation(liveItem = {}) {
      if (!this.isLogin) {
        const toNext = () => {
          this.getMoreLiveLists();
        };
        this.showLoginDialog({
          callback: toNext,
        });
        return;
      }
      const { liveRoomTypeEnum, id } = liveItem;
      // 回放制直接进入回放页面
      if (liveRoomTypeEnum === 'CLOSED') {
        const routes = this.$router.resolve({
          path: `/replay/${id}`,
        });
        window.open(routes.href, '_blank');
        return;
      }
      // 立即预约按钮进行权限判断
      if (liveRoomTypeEnum === 'SUBSCRIBED') {
        this.subscribeOperation(liveItem);
      }
    },
    // 点击立即预约按钮操作
    async subscribeOperation(liveItem) {
      const { id, preStartTime } = liveItem;
      try {
        const res = await subscribeLive({
          id,
        });
        this.roomId = id;
        if (res?.code === 403) {
          this.$message.error(res?.data?.message || '登录信息失效，请重新登录');
          return;
        }
        // 预约失败
        if (res?.code === 1) {
          this.showFooter = false;
          this.SubscribeTipDialogContent = res.msg;
          this.toggleSubscribeTipDialog(true);
          return;
        }
        // 进入直播间需要申请
        if (res?.code === 1001) {
          this.showFooter = true;
          this.SubscribeTipDialogContent = res.msg;
          this.dialogType = 'apply';
          this.toggleSubscribeTipDialog(true);
          return;
        }
        // 进入直播间需要认证创业者身份
        if (res?.code === 1002) {
          this.showFooter = true;
          this.SubscribeTipDialogContent = res.msg;
          this.dialogType = 'certify';
          this.toggleSubscribeTipDialog(true);
          return;
        }
        this.bookModel = true;
        this.subscribRoomPreTime = preStartTime;
        this.getMoreLiveLists();
      } catch (error) {
        // console.log(error)
      }
    },
    // 获取查看回放的人数或者预约的人数
    getAppointNumber(liveItem = {}) {
      const { liveRoomTypeEnum, preCount, totalViewCount } = liveItem;
      let count = '';
      if (liveRoomTypeEnum === 'SUBSCRIBED') {
        count = preCount;
      }
      if (liveRoomTypeEnum === 'CLOSED') {
        count = totalViewCount;
      }
      return count >= 100000 ? '10w+' : count;
    },
    // 获取播放时间的文案
    getAirtimeText(liveItem = {}) {
      const { liveRoomTypeEnum, startTime, preStartTime } = liveItem;
      let content = '';
      if (liveRoomTypeEnum === 'SUBSCRIBED') {
        content = `${moment(preStartTime).format('YYYY-MM-DD HH:mm')} 开播`;
      }
      if (liveRoomTypeEnum === 'CLOSED') {
        content = moment(startTime).format('YYYY-MM-DD');
      }
      return content;
    },
    // 获取观看人数的文案
    getAppointmentText(liveItem = {}) {
      const { liveRoomTypeEnum } = liveItem;
      if (liveRoomTypeEnum === 'SUBSCRIBED') {
        return '预约';
      }
      if (liveRoomTypeEnum === 'CLOSED') {
        return '观看';
      }
    },
    // 获取图片上标签的文案和图标
    getLabelContent(liveItem = {}) {
      const { liveRoomTypeEnum } = liveItem;
      if (liveRoomTypeEnum === 'SUBSCRIBED') {
        return {
          icon: 'iconfont icon-yuyue',
          text: '预约中',
        };
      }
      if (liveRoomTypeEnum === 'CLOSED') {
        return {
          icon: 'iconfont icon-huifang',
          text: '直播回放',
        };
      }
    },
    // 处理banner和title的跳转
    handleJump(liveItem = {}) {
      const { id, liveRoomTypeEnum } = liveItem;
      let routeType = '';
      // 预约
      if (liveRoomTypeEnum === 'SUBSCRIBED') {
        routeType = 'live';
      }
      // 回放
      if (liveRoomTypeEnum === 'CLOSED') {
        routeType = 'replay';
      }
      if (routeType) {
        const routes = this.$router.resolve({
          path: `/${routeType}/${id}`,
        });
        window.open(routes.href, '_blank');
      }
    },
    toggleSubscribeTipDialog(visible) {
      this.SubscribeTipDialogVisible = visible;
    },
  },
};
</script>
<style lang="scss">
._home-more-live-tooltip_ {
  &.is-dark {
    background: #546880 !important;
  }
}
</style>
<style lang="scss" scoped>
.more-live-wrapper {
  .title {
    font-weight: 700;
    font-size: 20px;
    color: #333b44;
    letter-spacing: 0;
    line-height: 24px;
    margin: 16px 0 24px;
  }

  .title-no-live {
    display: flex;
    justify-content: space-between;

    span {
      display: inline-block;
    }

    .operation-btn {
      font-weight: 400;
      width: 120px;
      font-size: 14px;
      //color: #333b44;
      letter-spacing: 2px;
      text-align: center;
      padding: 8px 15px;
      position: relative;
      top: -4px;
    }
  }

  .more-live-lists {
    display: flex;
    flex-wrap: wrap;
  }

  .live-item {
    width: calc((100% - 48px) / 3);
    margin-right: 24px;
    margin-bottom: 32px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .label-group {
      display: flex;
      align-items: center;
      margin: 12px 0 4px;
    }

    .live-experience {
      background: #00b499;
      border-radius: 2px;
      width: 52px;
      height: 20px;
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      font-weight: 500;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  .live-posters {
    width: 100%;
    height: 196px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
    }

    .label-wrapper {
      position: absolute;
      padding: 0 4px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      left: 16px;
      top: 16px;
      font-weight: 700;
      color: #fff;
      font-size: 12px;

      > i.iconfont {
        font-size: 16px;
        vertical-align: bottom;
        font-weight: 400;
      }
    }
  }

  .live-title {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    font-size: 16px;
    color: #333b44;
    line-height: 22px;
    //margin: 12px 0 4px;

    &:hover {
      color: #3e74ca;
    }
  }

  .live-detail {
    font-size: 14px;
    color: #959aa2;
    line-height: 20px;
    margin-bottom: 8px;
    display: flex;

    .live-airtime {
      .iconfont {
        vertical-align: bottom;
      }
    }

    .appointment-count {
      margin-left: 16px;
      display: flex;
      align-items: center;

      > img {
        width: 14px;
        height: 14px;
      }

      .appointment-icon {
        margin-right: 4px;
      }
    }
  }

  .live-operation-btn {
    .watch-btn {
      width: 120px;
      font-size: 14px;
      letter-spacing: 2px;
      text-align: center;
      font-weight: 400;
      padding: 8px 15px;
    }

    .watch-btn.subscribe-btn {
      &:hover {
        background-color: #4c8bee;
        border-color: #4c8bee;
      }
    }

    .watch-btn.is-disabled {
      background: #f2f4f6;
      color: #959aa2;
      border-color: #f2f4f6;

      &:hover {
        background: #f2f4f6;
        color: #959aa2;
        border-color: #f2f4f6;
      }
    }
  }

  .empty-class {
    height: 500px;
    padding-top: 200px;
  }
}
</style>
