var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"live-now-wrapper"},[(_vm.liveLists.length)?_c('div',[_c('div',{staticClass:"title-wrapper"},[_c('h1',{staticClass:"title"},[_vm._v(" Live Now ")]),_c('div',[_c('el-button',{directives:[{name:"sensor",rawName:"v-sensor:click",value:({
            $element_id: 'my_live',
            consumer_id: _vm.profile.uid
          }),expression:"{\n            $element_id: 'my_live',\n            consumer_id: profile.uid\n          }",arg:"click"}],staticClass:"operation-btn",attrs:{"size":"small","plain":""},on:{"click":_vm.handleMyLive}},[_vm._v(" 我的Live ")]),_c('el-button',{directives:[{name:"sensor",rawName:"v-sensor:click",value:({
            $element_id: 'live_publish',
            consumer_id: _vm.profile.uid
          }),expression:"{\n            $element_id: 'live_publish',\n            consumer_id: profile.uid\n          }",arg:"click"}],staticClass:"operation-btn",attrs:{"size":"small","plain":""},on:{"click":_vm.handleLive}},[_vm._v(" 发起Live ")])],1)]),_c('div',{staticClass:"live-now-lists"},_vm._l((_vm.liveLists),function(liveNowItem){return _c('div',{key:liveNowItem.id,staticClass:"live-now-item"},[_c('div',{staticClass:"live-posters",on:{"click":function($event){return _vm.handleToDetail(liveNowItem.id)}}},[_c('img',{attrs:{"src":_vm.imgCompression(liveNowItem.posterUrl)}}),_vm._m(0,true)]),_c('div',{staticClass:"live-right-wrapper"},[_c('div',{staticClass:"live-right-wrapper-top"},[_c('div',{staticClass:"live-right-title-group"},[(liveNowItem.experienceStatus)?_c('div',{staticClass:"live-experience"},[_vm._v(" 体验 ")]):_vm._e(),_c('div',{staticClass:"live-right-title",on:{"click":function($event){return _vm.handleToDetail(liveNowItem.id)}}},[_vm._v(" "+_vm._s(liveNowItem.liveTheme)+" ")])]),_c('div',{staticClass:"live-right-introduce",domProps:{"innerHTML":_vm._s(liveNowItem.introduce)}}),_c('div',{staticClass:"live-right-users"},[_c('div',{staticClass:"user-avatar-lists"},_vm._l((liveNowItem.users),function(item,index){return _c('div',{key:item.uid,staticClass:"user-avatar-wrapper"},[(index < 9 && item.avatar)?_c('img',{attrs:{"src":item.avatar}}):_vm._e(),(index < 9 && !item.avatar)?_c('span',{staticClass:"user-avatar-default",style:({
                      background: _vm.getRandomColor(),
                    })},[_vm._v(" "+_vm._s(item.name && item.name[0] || '新')+" ")]):_vm._e()])}),0),_c('div',{staticClass:"user-count"},[_c('img',{staticClass:"hot-gif",attrs:{"src":require("@/assets/img/hot.svg")}}),_vm._v(" "+_vm._s(liveNowItem.totalViewCount >= 100000 ? '10w+' : liveNowItem.totalViewCount)+" 人围观 ")])])]),_c('div',{staticClass:"live-right-btn"},[_c('el-button',{staticClass:"watch-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleWatch(liveNowItem.id)}}},[_c('i',{staticClass:"iconfont icon-v2_play2"}),_c('span',{staticClass:"watch-text"},[_vm._v("立即观看")])])],1)])])}),0)]):(_vm.isEmpty)?_c('div',{staticClass:"empty-wrapper"},[_vm._m(1),_c('p',[_vm._v("精彩内容正在路上，敬请期待，快开启你的live之旅吧~")]),_c('el-button',{directives:[{name:"sensor",rawName:"v-sensor:click",value:({
        $element_id: 'live_publish',
        consumer_id: _vm.profile.uid
      }),expression:"{\n        $element_id: 'live_publish',\n        consumer_id: profile.uid\n      }",arg:"click"}],staticClass:"operation-btn empty-operation-btn",attrs:{"size":"medium"},on:{"click":_vm.handleLive}},[_vm._v(" 发起Live ")])],1):_vm._e(),_c('launch-live-dialog',{attrs:{"visible":_vm.liveDialogVisible},on:{"close":function($event){_vm.liveDialogVisible = false}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-wrapper"},[_c('img',{staticClass:"live-gif",attrs:{"src":require("@/assets/img/LarkLive.gif")}}),_vm._v(" 直播中 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"empty-text"},[_c('img',{attrs:{"src":require("./img/text_livebanner@2x.png")}})])}]

export { render, staticRenderFns }