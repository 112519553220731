<template>
  <div class="live-now-wrapper">
    <div
      v-if="liveLists.length"
    >
      <div class="title-wrapper">
        <h1 class="title">
          Live Now
        </h1>
        <div>
          <el-button
            v-sensor:click="{
              $element_id: 'my_live',
              consumer_id: profile.uid
            }"
            size="small"
            class="operation-btn"
            plain
            @click="handleMyLive"
          >
            我的Live
          </el-button>
          <el-button
            v-sensor:click="{
              $element_id: 'live_publish',
              consumer_id: profile.uid
            }"
            size="small"
            class="operation-btn"
            plain
            @click="handleLive"
          >
            发起Live
          </el-button>
        </div>
      </div>
      <div
        class="live-now-lists"
      >
        <div
          v-for="liveNowItem in liveLists"
          :key="liveNowItem.id"
          class="live-now-item"
        >
          <div
            class="live-posters"
            @click="handleToDetail(liveNowItem.id)"
          >
            <img :src="imgCompression(liveNowItem.posterUrl)">
            <div class="label-wrapper">
              <img
                class="live-gif"
                src="@/assets/img/LarkLive.gif"
              >
              直播中
            </div>
          </div>
          <div class="live-right-wrapper">
            <div class="live-right-wrapper-top">
              <div class="live-right-title-group">
                <div
                  v-if="liveNowItem.experienceStatus"
                  class="live-experience"
                >
                  体验
                </div>
                <div
                  class="live-right-title"
                  @click="handleToDetail(liveNowItem.id)"
                >
                  {{ liveNowItem.liveTheme }}
                </div>
              </div>
              <div
                class="live-right-introduce"
                v-html="liveNowItem.introduce"
              />
              <div class="live-right-users">
                <div class="user-avatar-lists">
                  <div
                    v-for="(item, index) in liveNowItem.users"
                    :key="item.uid"
                    class="user-avatar-wrapper"
                  >
                    <img
                      v-if="index < 9 && item.avatar"
                      :src="item.avatar"
                    >
                    <span
                      v-if="index < 9 && !item.avatar"
                      class="user-avatar-default"
                      :style="{
                        background: getRandomColor(),
                      }"
                    >
                      {{ item.name && item.name[0] || '新' }}
                    </span>
                  </div>
                </div>
                <div class="user-count">
                  <img
                    class="hot-gif"
                    src="@/assets/img/hot.svg"
                  >
                  {{
                    liveNowItem.totalViewCount >= 100000 ? '10w+' : liveNowItem.totalViewCount
                  }} 人围观
                </div>
              </div>
            </div>
            <div class="live-right-btn">
              <el-button
                type="primary"
                class="watch-btn"
                @click="handleWatch(liveNowItem.id)"
              >
                <i class="iconfont icon-v2_play2" />
                <span class="watch-text">立即观看</span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="isEmpty"
      class="empty-wrapper"
    >
      <div class="empty-text">
        <img
          src="./img/text_livebanner@2x.png"
        >
      </div>
      <p>精彩内容正在路上，敬请期待，快开启你的live之旅吧~</p>
      <el-button
        v-sensor:click="{
          $element_id: 'live_publish',
          consumer_id: profile.uid
        }"
        size="medium"
        class="operation-btn empty-operation-btn"
        @click="handleLive"
      >
        发起Live
      </el-button>
    </div>

    <!-- 未认证用户提示认证弹框 -->
    <launch-live-dialog
      :visible="liveDialogVisible"
      @close="liveDialogVisible = false"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { getLiveRoomLists } from '@/services/home';
import { getRandomColor } from '@/utils/logoColors';
import { imgCompression } from '@/utils/imgCompression';
import LaunchLiveDialog from '@/views/components/LaunchLiveDialog';

export default {
  components: {
    LaunchLiveDialog,
  },
  data() {
    return {
      isEmpty: false,
      liveLists: [],
      liveDialogVisible: false,
    };
  },
  computed: {
    ...mapState('user', [
      'isLogin', 'profile',
    ]),
    ...mapGetters('user', [
      'isEnterpriser',
      'isInvestor',
      'isOrg',
    ]),
  },
  created() {
    this.getNowLiveLists();
  },

  methods: {
    ...mapActions('login', [
      'showLoginDialog',
    ]),
    getRandomColor,
    imgCompression,
    async getNowLiveLists() {
      try {
        const res = await getLiveRoomLists({
          page: 1,
          pageSize: 100,
          liveRoomType: 'ONLINE',
        });
        const { data } = res;
        this.liveLists = [].concat(data);
        if (!this.liveLists.length) {
          this.isEmpty = true;
          this.$emit('liveEmpty', true);
        } else {
          this.isEmpty = false;
        }
      } catch (error) {
        this.$message.error(error?.response?.data?.msg || '后台出错，请联系管理员！');
      }
    },
    handleMyLive() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      const routes = this.$router.resolve({
        path: 'myLive',
      });
      window.open(routes.href, '_blank');
    },
    // 发起live
    handleLive() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      if (!this.isEnterpriser && !this.isInvestor && !this.isOrg) { // 无认证用户身份引导认证
        this.liveDialogVisible = true;
        return;
      }
      const routes = this.$router.resolve({
        path: 'publish',
      });
      window.open(routes.href, '_blank');
    },
    // 跳转至直播详情页
    handleToDetail(id) {
      const routes = this.$router.resolve({
        path: `/liveroom/${id}`,
      });
      window.open(routes.href, '_blank');
    },
    // 立即观看
    handleWatch(id) {
      if (!this.isLogin) {
        this.showLoginDialog();
      } else {
        const routes = this.$router.resolve({
          path: `/liveroom/${id}`,
        });
        window.open(routes.href, '_blank');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.live-now-wrapper {
  .title-wrapper {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 21px 0;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    color: #333b44;
    letter-spacing: 0;
    line-height: 24px;
  }

  .operation-btn {
    font-weight: 400;
    width: 120px;
    font-size: 14px;
    //color: #333b44;
    letter-spacing: 2px;
    text-align: center;
    padding: 8px 15px;
  }

  .live-now-lists {
    .live-now-item {
      margin-bottom: 24px;
      display: flex;

      &:last-of-type {
        margin-bottom: 40px;
      }
    }

    .live-posters {
      width: 500px;
      height: 280px;
      border-radius: 6px;
      flex-shrink: 0;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      border: solid 1px rgba(0, 0, 0, 0.06);

      & > img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }

    .label-wrapper {
      position: absolute;
      height: 18px;
      line-height: 18px;
      background: rgba(255, 67, 67, 0.8);
      border-radius: 2px;
      left: 16px;
      top: 16px;
      color: #fff;
      font-size: 12px;
      font-family: PingFangSC-Semibold;
      display: flex;
      padding: 0 4px;
      align-items: center;

      .live-gif {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
    }

    .live-right-wrapper {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 24px;
      flex: 1;
      width: 0;

      .live-right-title-group {
        display: flex;
        align-items: center;
      }

      .live-experience {
        background: #00b499;
        border-radius: 2px;
        width: 52px;
        height: 20px;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        font-weight: 500;
        margin-right: 8px;
        flex-shrink: 0;
      }

      .live-right-title {
        font-weight: 500;
        font-size: 22px;
        color: #333b44;
        letter-spacing: 0;
        line-height: 28px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      .live-right-title:hover {
        color: #3e74ca;
      }

      .live-right-introduce {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 4;
        font-size: 14px;
        color: #333b44;
        line-height: 24px;
        white-space: pre-line;
        margin: 16px 0;
      }

      .live-right-users {
        display: flex;
        align-items: center;
      }

      .user-avatar-lists {
        display: flex;
        align-items: center;
      }
    }

    .user-avatar-wrapper {
      width: 32px;
      height: 32px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      margin-left: -6px;
      border-radius: 50%;

      &:first-of-type {
        margin-left: 0;
      }

      > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      > .user-avatar-default {
        // display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 14px;
      }
    }

    .user-count {
      margin-left: 16px;
      font-size: 14px;
      color: #959aa2;
      letter-spacing: 0;
      line-height: 18px;
      display: flex;

      .hot-gif {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }

  .empty-wrapper {
    margin: 24px 0 40px;
    width: 100%;
    height: 280px;
    background: url(./img/img_livebanner@2x.png) top / 100% no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;

    .empty-text {
      width: 310px;
      height: 44px;
      margin: 69px 0 16px;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > p {
      font-size: 14px;
      color: #fff;
      margin-bottom: 32px;
    }

    .empty-operation-btn {
      width: 160px;
      font-weight: 700;
      font-size: 16px;
      color: #3e74ca;
      text-align: center;

      &:hover {
        background: #fff;
      }
    }
  }

  .watch-btn {
    width: 152px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    letter-spacing: 2px;
    text-align: center;
    padding: 10px 22px;

    /deep/ i.iconfont {
      vertical-align: bottom;
    }

    .watch-text {
      font-size: 16px;
      margin-left: 8px;
    }

    &:hover {
      background-color: #4c8bee;
      border-color: #4c8bee;
    }
  }
}
</style>
